<template>
  <div class="page-club-questions page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ title }}</b></p>
      <p>{{ description }}</p>
    </KlubbaRichText>

    <div class="container" :class="{ 'is-loading': loading }">
      <div class="card-wrap">
        <div v-for="(detail, detailKey) in details" :key="detailKey">
          <span>{{ detail.title }}</span>
          <span :class="detail.valueClass">{{ detail.value }}</span>
        </div>
      </div>

      <KlubbaLoaderIcon :active="loading" />
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import swal from 'sweetalert';
import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';
import firebaseApp from '../../config/firebase';
import setOrUpdateRef from '../../tools/setOrUpdateRef';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaFooter,
  },
  data() {
    return {
      loading: false,
      title: 'Confirm all details',
      description: 'Please check that your contact information and subscription details are correct.',
    };
  },
  computed: {
    ...mapGetters([
      'club',
      'selectedClubSubscription',
      'billingInfo',
      'firebaseUser',
    ]),
    first_name() {
      return this.$store.getters.getUserKey('first_name');
    },
    last_name() {
      return this.$store.getters.getUserKey('last_name');
    },
    details() {
      return [
        {
          title: 'Subscription',
          value: this.selectedClubSubscription?.name ?? '',
        },
        {
          title: 'Amount',
          value: `A$${this.selectedClubSubscription?.price ?? 0}`,
        },
        {
          title: 'Paid',
          value: 'Monthly',
        },
        {
          title: 'Start Date',
          value: this.billingInfo?.billing_date ? moment(this.billingInfo.billing_date === 'now' ? Date.now() : this.billingInfo.billing_date).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY'),
        },
        {
          title: 'Next payment due',
          value: this.billingInfo?.billing_date ? moment(this.billingInfo.billing_date === 'now' ? Date.now() : this.billingInfo.billing_date).add(1, 'month').format('DD-MM-YYYY') : moment().format('DD-MM-YYYY'),
          valueClass: 'next-billing',
        },
        {
          title: 'Auto-renew',
          value: this.billingInfo?.renew ? 'Yes' : 'No',
        },
        {
          title: 'First Name',
          value: this?.first_name ?? '',
        },
        {
          title: 'Last Name',
          value: this?.last_name ?? '',
        },
      ];
    },
  },
  methods: {
    moment,
    prevPage() {
      if (this.club?.medical_conditions?.length > 0 || this.club?.allergies?.length > 0) {
        this.$router.push(`/${this.club.id}/additional`);
      } else {
        this.$router.push(`/${this.club.id}/club-questions`);
      }
    },
    async nextPage() {
      const db = firebaseApp.firestore();
      this.loading = true;

      const clubSpecificRef = db.collection('users').doc(this.firebaseUser.uid).collection('club_specific').doc(this.club.id);
      const clubSpecificData = this.$store.getters.newUserClubSpecific;

      await setOrUpdateRef(clubSpecificRef, clubSpecificData)
        .then(() => {
          this.$router.push(`/${this.club.id}/payment`);
        })
        .catch((error) => {
          swal('Error', error.message, 'error');
        });

      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8 relative
  &.is-loading .card-wrap
    opacity: .3

.button-wrap
  @apply p-8 mt-auto

.card-wrap
  @apply bg-white rounded-radius-10 p-5 tracking-spacing-7 relative
  transition: opacity var(--transition)
  div
    @apply flex justify-between
    font-size: 17px
    line-height: normal
    word-break: break-word

    span:first-child
      margin-right: 20px
      flex-shrink: 0
      white-space: nowrap

    &:not(:last-child)
      @apply mb-3 pb-3
      border-bottom: 1px solid rgba(0, 0, 0, 0.1)

.next-billing
  @apply text-black-light
</style>
