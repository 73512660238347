/* eslint-disable */
export default async function setOrUpdateRef(firebaseRef, data, options = { showInConsole: true, updateOnly: false, setOnly: false }) {
  return new Promise((resolve, reject) => {
    const [showInConsole, updateOnly, setOnly] = Object.values(options);

    firebaseRef.get().then((doc) => {
      if (!doc.exists && !updateOnly) {
        // Set document
        doc.ref.set(data)
          .then((result) => {
            if (showInConsole) console.log(`🠖 Firebase set ${firebaseRef.path} with data and result:`, data, result);
            resolve({ action: 'set', result });
          })
          .catch((error) => {
            if (showInConsole) console.error(`🠖 Firebase set error. Path: ${firebaseRef.path}, data and error:`, data, error);
            reject({ action: 'set', error });
          });
      } else if (doc.exists && !setOnly) {
        // Update document
        doc.ref.update(data).then((result) => {
          if (showInConsole) console.log(`🠖 Firebase update ${firebaseRef.path} with data:`, data);
          resolve({ action: 'update', result });
        }).catch((error) => {
          if (showInConsole) console.error(`🠖 Firebase update error. Path: ${firebaseRef.path}, data and error:`, data, error);
          reject({ action: 'update', error });
        });
      } else {
        resolve({ action: null })
      }
    });
  });
}
/* eslint-enable */
